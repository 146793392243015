const host = 'http://20.39.194.164:3000/openai';
// const host = 'http://localhost:3001/openai';

export function getUrl(api: string): string {
    return `${host}${api.startsWith('/') ? api : '/' + api}`;
}

export async function post(api: string, data: any): Promise<any> {
    const res = await fetch(getUrl(api), {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
    const json = await res.json();
    if (json.success) {
        return json.data;
    }
    throw new Error(json.data);
}

export async function get(api: string, data: any): Promise<any> {
    const search = new URLSearchParams(data).toString();
    const res = await fetch(`${getUrl(api)}?${search}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        }
    });
    const json = await res.json();
    if (json.success) {
        return json.data;
    }
    throw new Error(json.data);
}

export async function upload(api: string, file: File): Promise<any> {
    const formData = new FormData();
    formData.append('file', file);
    const res = await fetch(getUrl(api), {
        method: 'POST',
        mode: 'cors',
        body: formData
    });
    const json = await res.json();
    if (json.success) {
        return json.data;
    }
    throw new Error(json.data);
}
