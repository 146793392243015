import React, { useCallback, useState } from 'react';
import { Col, Input, Row, Select, notification } from 'antd';

import { StContainer, StImage, StImageContainer, StInputRow, StSlider } from './ImgGen.style';
import Header from '../../components/Header/Header';
import { post } from '../../libs/request';

const { Search } = Input;

const sizeOptions = [
    { value: '1024x1024', label: '正方形 (1024x1024)' },
    { value: '1792x1024', label: '横屏 (1792x1024)' },
    { value: '1024x1792', label: '竖屏 (1024x1792)' },
];
const styleOptions = [
    { value: 'vivid', label: '生动' },
    { value: 'natural', label: '自然' },
];
const qualityOptions = [
    { value: 'starndard', label: '标准' },
    { value: 'hd', label: '高' },
];

const ImgGen = () => {
    const [loading, setLoading] = useState(false);
    // const [n, setN] = useState(3);
    const [size, setSize] = useState(sizeOptions[0].value);
    const [imgStyle, setImgStyle] = useState(styleOptions[0].value);
    const [quality, setQuality] = useState(qualityOptions[0].value);
    const [images, setImages] = useState<string[]>([]);

    // const onSlideChange = useCallback((v: number) => setN(v), []);

    const onSearch = useCallback(async (text: string) => {
        try {
            setLoading(true);
            const res = await post('/imgGen', {
                prompt: text,
                size,
                style: imgStyle,
                quality,
            });
            setImages(res);
        } catch (e: any) {
            console.error(e);
            notification.error({
                message: e.message || '生成失败'
            });
        } finally {
            setLoading(false);
        }
    }, [size, imgStyle, quality]);

    return (
        <StContainer>
            <Header />
            <Row>
                {/* <Col span={6} sm={12} xs={12}>
                    <StInputRow>
                        Count
                        <StSlider
                            value={n}
                            max={10}
                            min={1}
                            step={1}
                            tooltip={{ open: false }}
                            onChange={onSlideChange}
                        />
                        {n}
                    </StInputRow>
                </Col> */}
                <Col md={8} sm={24} xs={24}>
                    <StInputRow>
                        尺寸&nbsp;
                        <Select size="middle" style={{ flex: 1 }} value={size} onChange={setSize} options={sizeOptions} />
                    </StInputRow>
                </Col>
                <Col md={6} sm={24} xs={24}>
                    <StInputRow>
                        风格&nbsp;
                        <Select size="middle" style={{ flex: 1 }} value={imgStyle} onChange={setImgStyle} options={styleOptions} />
                    </StInputRow>
                </Col>
                <Col md={6} sm={24} xs={24}>
                    <StInputRow>
                        质量&nbsp;
                        <Select size="middle" style={{ flex: 1 }} value={quality} onChange={setQuality} options={qualityOptions} />
                    </StInputRow>
                </Col>
            </Row>
            <StInputRow>
                <Search
                    placeholder="input text"
                    enterButton="Go"
                    loading={loading}
                    onSearch={onSearch}
                />
            </StInputRow>
            <StImageContainer>
                {images.map(el => (
                    <a key={el} href={el} target="_blank" rel="noreferrer" >
                        <StImage src={el} />
                    </a>
                ))}
            </StImageContainer>
        </StContainer>
    );
};

export default ImgGen;
