import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./index.module.css";
import Header from "../../components/Header/Header";
import { Button, Col, Modal, notification, Row, Segmented, Upload, UploadFile } from "antd";
import { get, post, upload } from "../../libs/request";
import { WishperResponse } from "./type";
import ReactMarkdown from "react-markdown";
import { whisperStorage } from "./storage";

const Whisper = () => {
    const [file, setFile] = useState<UploadFile>();
    const [uploading, setUploading] = useState(false);
    const [status, setStatus] = useState('');
    const [error, setError] = useState('');
    const [id, setId] = useState('');
    const [data, setData] = useState<WishperResponse>();
    const timerId = useRef<any>();
    const [historyVisible, setHistoryVisible] = useState(false);

    const getResult = (async (id: string, fileName?: string) => {
        try {
            const res = await get(`/whisper/result`, { id });
            setStatus(s => s === res.status ? s : res.status);
            if (res.status === 'pending') {
                timerId.current = setTimeout(() => getResult(id, fileName), 1000);
            } else {
                clearTimeout(timerId.current);
                if (res.status === 'error') {
                    setError(res.text);
                } else {
                    setId(id);
                    setData(res.result);
                    whisperStorage.updateData(id, {
                        ...res.result,
                        filename: fileName || `未命名-${new Date().toLocaleString()}`
                    });
                }
            }
        } catch (e: any) {
            console.error(e);
            notification.error({ message: e.message });
        }
    });

    const onUpload = async () => {
        try {
            setUploading(true);
            const res = await upload('/whisper/split', file as any);
            console.log(file);
            getResult(res.id, file?.name);
        } catch (e: any) {
            console.error(e);
            notification.error({ message: e.message });
        } finally {
            setUploading(false);
        };
    }

    useEffect(() => {
        return () => {
            clearTimeout(timerId.current);
        };
    }, []);

    return (
        <div>
            <Header />
            <Row>
                <Col xs={24} sm={24} md={8}>
                    <div className={styles.uploader}>
                        <Upload.Dragger
                            fileList={file ? [file] : []}
                            maxCount={1}
                            beforeUpload={(f) => {
                                setFile(f);
                                return false;
                            }}
                        >
                            <div className={styles.uploadFilIcon}>📂</div>
                            <p>点击选择文件<br />或拖拽文件到这里</p>
                        </Upload.Dragger>
                        <div className={styles.uploadButtons}>
                            <div>
                                <Button
                                    type="primary"
                                    disabled={uploading || !file || status === 'pending'}
                                    onClick={onUpload}
                                >
                                    📤︎ 上传
                                </Button>
                                &emsp;
                                <Button
                                    onClick={() => {
                                        setFile(undefined);
                                        setStatus('');
                                        setError('');
                                        setData(undefined);
                                    }}
                                >
                                    重置
                                </Button>
                            </div>
                            <Button
                                type="link"
                                onClick={() => setHistoryVisible(true)}
                            >
                                历史记录
                            </Button>
                        </div>
                    </div>
                </Col>
                {!!status && (
                    <Col xs={24} sm={24} md={16}>
                        {status === 'pending' && <div>正在处理中...</div>}
                        {status === 'error' && <div>错误：{error}</div>}
                        {status === 'done' && !!data && <Details id={id} data={data} />}
                    </Col>
                )}
            </Row>
            {historyVisible && <HistoryModal key={Math.random()} onClose={() => setHistoryVisible(false)} onSelect={id => {
                setHistoryVisible(false);
                getResult(id);
            }} />}
        </div>
    );
};

const tabs = [
    { label: '总结', value: 'summary' },
    { label: '原始内容', value: 'original' },
];

function Details({ id, data }: { id: string, data: WishperResponse }) {
    const [tab, setTab] = useState(tabs[0].value);
    const [summary, setSummary] = useState('');
    const [summaryLoading, setSummaryLoading] = useState(true);

    const generateSummary =
        useCallback(async (id: string, data: WishperResponse, regen = false) => {
            const cache = whisperStorage.getSummary(id);
            if (!regen && cache) {
                setSummary(cache);
                setSummaryLoading(false);
                return;
            }
            setSummaryLoading(true);
            try {
                const { message } = await post('/chat', {
                    messages: [
                        {
                            role: 'system', content: `# Use Chinese to response
# Genereate summary, important infomation and TODO from user input
# Return in markdown, highlight the title
# This is a phone recording of a headhunter and candidate
# DO NOT include the raw content
# DO NOT complete the chat content` },
                        { role: 'user', content: data.phrases.map(item => `对话者${item.speaker}: ${item.text}`).join('\n') }
                    ]
                });
                setSummary(message);
                whisperStorage.updateSummary(id, message);
            } catch (e: any) {
                console.error(e);
                notification.error({ message: e.message });
            } finally {
                setSummaryLoading(false);
            }
        }, []);

    useEffect(() => {
        generateSummary(id, data);
    }, [id, data]);

    return (
        <div className={styles.content}>
            <Segmented
                options={tabs}
                onChange={setTab as any}
            />
            {tab === 'summary' && (
                <div className={styles.content}>{summaryLoading ? '生成中...' : (
                    <div>
                        <ReactMarkdown>{summary}</ReactMarkdown>
                        <Button onClick={() => generateSummary(id, data, true)}>重新生成</Button>
                    </div>
                )}</div>
            )}
            {tab === 'original' && (
                <ul>
                    {data.phrases.map((item, index) => (
                        <li key={index}>
                            <strong className={`${styles.speaker} ${styles[`s${(+item.speaker || 0) % 9 + 1}`]}`}>
                                对话者{item.speaker}:&nbsp;
                            </strong>
                            <span>{item.text}</span>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}

function HistoryModal({ onClose, onSelect }: { onClose: () => void, onSelect: (id: string) => void }) {
    const [list, setList] = useState<any[]>([]);

    useEffect(() => {
        setList(whisperStorage.list);
    }, []);

    const onRemoveClick = (id: string) => {
        Modal.confirm({
            title: '删除这条记录',
            onOk: () => {
                whisperStorage.removeId(id);
                setList(whisperStorage.list);
            }
        });
    };

    const onClearClick = () => {
        Modal.confirm({
            title: '清空所有记录',
            onOk: () => {
                whisperStorage.clear();
                setList(whisperStorage.list);
            }
        });
    }

    return (
        <Modal open title="历史记录" onCancel={onClose} footer={null}>
            <>
                <Button onClick={onClearClick} danger>清空</Button>
                {list.map(item => (
                    <div key={item.id}>
                        {item.name}
                        <Button onClick={() => onSelect(item.id)} type="link">载入</Button>
                        <Button onClick={() => onRemoveClick(item.id)} type="link" danger>删除</Button>
                    </div>
                ))}
            </>
        </Modal>
    );

}

export default Whisper;
