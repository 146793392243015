import { WishperResponse } from "./type";

class WhisperStorage {
    private idListKey = 'whisper-idList';
    private dataKey = 'whisper-data';
    private summaryKey = 'whisper-summary';

    private idList: {
        id: string;
        name: string;
    }[] = [];

    get list() {
        return this.idList;
    }

    constructor() {
        this.idList = JSON.parse(localStorage.getItem(this.idListKey) || '[]');
    }

    clear() {
        this.idList.forEach(item => {
            localStorage.removeItem(`${this.dataKey}-${item.id}`);
            localStorage.removeItem(`${this.summaryKey}-${item.id}`);
        });
        this.idList = [];
        localStorage.removeItem(this.idListKey);
    }

    updateData(id: string, data: WishperResponse) {
        this.updateItem(this.dataKey, id, data);
    }

    getData(id: string): WishperResponse | null {
        return this.getItem(this.dataKey, id);
    }

    updateSummary(id: string, summary: string) {
        this.updateItem(this.summaryKey, id, summary);
    }

    getSummary(id: string): string | null {
        return this.getItem(this.summaryKey, id);
    }

    removeId(id: string) {
        const index = this.idList.findIndex(item => item.id === id);
        if (index >= 0) {
            this.idList.splice(index, 1);
            localStorage.setItem(this.idListKey, JSON.stringify(this.idList));
        }
        localStorage.removeItem(`${this.dataKey}-${id}`);
        localStorage.removeItem(`${this.summaryKey}-${id}`);
    }

    private updateItem(keyType: string, id: string, data: any) {
        const exist = this.idList.find(item => item.id === id);
        if (!exist) {
            this.idList.push({ id, name: data.filename });
            localStorage.setItem(this.idListKey, JSON.stringify(this.idList));
        }
        const key = `${keyType}-${id}`;
        localStorage.setItem(key, JSON.stringify(data));
    }

    private getItem(keyType: string, id: string) {
        const key = `${keyType}-${id}`;
        const data = localStorage.getItem(key);
        if (!data) {
            return null;
        }
        return JSON.parse(data);
    }
}

export const whisperStorage = new WhisperStorage();
